//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import wx from 'weixin-js-sdk';
export default {
	data: ()=> ({
		imgUrls:[
			{ idx: 19, src: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-23/pro-poster-19@2x.jpg'},
			{ idx: 20, src: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-23/pro-poster-20@2x.jpg'},
			{
				idx: 17,
				src: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/pro-17.jpg',
			},
			{
				idx: 18,
				src: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/pro-18.jpg',
			},
			
			{
				idx: 16,
				src: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-9/产品海报16@2x.jpg',
			},
			{
				idx: 15,
				src: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-9/产品海报15@2x.jpg',
			},
			{
                idx:13,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-3-26/posterDemo13.png",
			},
			{
                idx:14,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-4-2/posterDemo14.jpg",
			},
			{
                idx:12,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-2-7/posterDemo12.png",
            },
			{
                idx:11,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-2-7/posterDemo11.png",
			},
            {
                idx:10,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-2-2/posterDemo10.png",
            },
            {
                idx:5,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-1-18/posterDemo5.png",
            },
            {
                idx:6,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-1-18/posterDemo6.png",
            },
             {
                idx:7,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-1-26/posterDemo7.png",
            },
            {
                idx:9,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-1-26/posterDemo9.png",
            },
            {
                idx:1,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-1-18/posterDemo1.png",
            },
            {
                idx:8,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-1-26/posterDemo8.png",
			},
			{
                idx:4,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-1-18/posterDemo4.png",
            },
            {
                idx:3,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-1-18/posterDemo3.png",
            },
            {
                idx:2,
                src: "https://doc.efreight.cn/weapp/zjhy/static/2021-1-18/posterDemo2.png",
			},

			
		],
		weAppEnv: false,
	}),
	metaInfo () {
      return {
        title: '选择海报模板',
      }
    },
	mounted() {
		let _this = this
		wx.miniProgram.getEnv(function(res) { 
			_this.weAppEnv = res.miniprogram
		})
	},
	methods: {
		choose(item) {
			let {shop_id = null, product_id = null} = this.$route.params
			if(shop_id && product_id) {
				let {idx} = item, src = ''
				if(idx < 15) {
					src = 'https://fabric.yctop.com/#/product/' + shop_id + '/' + product_id + '/' + idx + '?t=' + new Date().getTime()
				} else {
					src = 'https://fabric.yctop.com/#/product2/' + shop_id + '/' + product_id + '/' + idx + '?t=' + new Date().getTime()
				}
				if(this.weAppEnv) {
					wx.miniProgram.navigateTo({
						url: '/shop/painter/index?src=' + encodeURIComponent(src) + '&navigationBgColor=' + encodeURIComponent('#ffffff'),
					})
				} else {
					this.$toast('当前不是小程序环境:' + src)
				}

			}
		}
	}
}
